* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-container {
  display: flex;
}

main {
  padding: 10px;
}

.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

/* Sidebar */
.sidebar {
  background: rgb(0, 7, 61);
  color: white;
  height: 100vh;
  overflow-y: auto;
}

.top_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
}

.logo {
  font-size: 18px;
  line-height: 0;
  padding: 7px;
}

.bars {
  cursor: pointer;
  width: 30px;
}

.hide {
  display: none;
}

.search {
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 30px;
  padding: 10px;
}

.search input {
  border: none;
  margin-left: 10px;
  border-radius: 5px;
  background: rgb(238, 238, 238);
  color: #000;
}

.routes {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.link {
  display: flex;
  color: white;
  gap: 10px;
  text-decoration: none;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.link:hover {
  border-right: 4px solid white;
  color: #fff;
  background: rgb(45, 51, 89);
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

/* .active {
  border-right: 4px solid white;
  background: rgb(45, 51, 89);
} */
.link_text {
  white-space: nowrap;
  font-size: 15px;
}

.menu {
  display: flex;
  color: white;

  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}

.menu_item {
  display: flex;
  gap: 10px;
}

.menu_container {
  display: flex;
  flex-direction: column;
}

.menu_container .link {
  padding-left: 20px;
  border-bottom: #fff 0.5px solid;
}



/* my css */

.bgnav {
  background-color: #00073d !important;
}

.navbar-light .navbar-nav .nav-link {
  color: rgb(255 255 255) !important;
}

.navbar-light .navbar-brand {
  color: #fff !important;
}

.ml-3 {
  margin-left: 30px;
}

a.ml-3.nav-link.active,
a.nav-link.active {
  background: #fff !important;
  color: #000 !important;
  border-radius: 5px;
  border: 0 !important;
}

a.nav-link.active {
  color: #000 !important;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show>.nav-link {
  color: rgb(0 7 61) !important;
}

a#navbarScrollingDropdown {
  color: #fff !important;
}

.search_icon {
  cursor: pointer;
}

.navbar-light .navbar-toggler {
  color: rgb(255 255 255);
  border-color: rgb(255 255 255);
  padding: 4px;
  background: #fff;
}

@media screen and (max-width:1024px) {

  .navbar-light .navbar-nav .nav-link,
  .navbar-light .navbar-nav .show>.nav-link {
    margin-left: 0 !important;
    padding: 8px;
  }
}

.menutop {
  position: absolute;
  width: 100%;
  top: 0;
}

main {
  padding: 10px;
  width: 100%;
  overflow-y: scroll;
  box-sizing: border-box;
  display: block;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  background-color: #dfdfdf;
}

select.custom-select.custom-select-sm.form-control.form-control-sm {
  position: absolute;
  width: 15%;
  top: 3px;
}

.col-sm-12.col-md-6 {
  position: relative;
}

/* #react-aria6138140227-8-tabpane-first{
  display: inline !important;
  justify-content: space-between;
}
#react-aria4113635643-8-tabpane-first{
  display: flex !important;
  justify-content: space-between;
} */
.maindash .tabledash {
  width: 24% !important;
  display: inline-block;
}

.maindash .dashtab {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.dtv {
  text-align: right;
}

.dtv .dropdown.btn-group:nth-child(2),
.dtv .dropdown.btn-group:nth-child(3) {
  margin: 0 10px;
}

.lbv {
  font-size: 14px;
  color: #000;
  text-transform: uppercase;
  font-weight: 600;
}

.ddvtv button#dropdown-button-drop-0 {
  padding: 4px 8px;
  font-size: 15px;
}

.maindash ul.nav.nav-tabs button {
  background-color: #00073d;
  color: #fff;
}

.maindash .nav-tabs li:nth-child(2) {
  margin: 0 10px !important;
}

.maindash .nav-tabs li:nth-child(4) {
  margin: 0 10px !important;
}

.maindash .nav-tabs .nav-link.active,
.maindash .nav-tabs .nav-item.show .nav-link {
  color: #ffffff !important;
  background-color: #232854 !important;
}

.maindash table.tabledash.table {
  border-radius: 5px;
  box-shadow: 0 0 3px #816e6e;
}

.maindash .tblhd,
.maindash .tblbdy,
.maindash tfoot,
.maindash .bltr,
.maindash .tbltd,
.maindash .tblth {
  width: 100%;
  padding: 4px 8px;
  display: inline-flex;
  box-sizing: border-box;
}

.maindash .dashtab {
  padding: 10px 0;
}

.maindash .table> :not(:first-child) {
  border-top: 0 !important;
}

.hdv {
  background-color: #ccc;
}

.maindash {
  padding: 0 20px;
}

.lbv.clr {
  color: #00073d;
  font-weight: 700;
}

.tbussz .lbv {
  margin: 0 20px;
}

.tbussz button {
  width: 100px;
  text-align: left;
}



.insetdx {
  height: 350px;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 9px 9px 18px #d9d9d9,
    -9px -9px 18px #ffffff;
  margin-top: 12px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 10px;
}

.row.tophedfxdv {
  padding: 10px;
  color: #fff;
  font-size: 17px;
  background-color: #4b9ee5;
}

.todomx {
  font-weight: 600;
  display: block;
}

.dtvfx p {
  padding: 3px 12px;
  background-color: #ffc55ba3;
  font-size: 13px;
}

.text-right {
  text-align: right;
}

.text-right button {
  font-size: 12px;
  color: #fff;
  background: green;
  line-height: initial;
}

.text-right button:hover {
  color: #ccc;
}

.bg-dark-light {
  background: #00073d;
  border-radius: 5px;
}

.bg-dark-light button {
  margin: 0 15px;
  background-color: #fff;
  color: #00073d;
}

.titletopdv {
  background: #e0f761b8;
  border-radius: 3px;
  padding: 4px 10px;
  font-size: 16px;
  font-weight: 600;
  color: #000;
  margin: 10px 0 20px;
  text-transform: uppercase;
}

select.mrdv.form-select {
  width: auto;
  display: inherit;
  padding: 3px 30px 2px 5px;
  font-size: 15px;
}

.mxdvd .form-label {
  font-weight: 500;
}

.mxdvd span {
  font-size: 14px;
  color: #6e6464;
}

button.btn.btn-sm.alert-danger {
  background-color: #b90f1e;
  color: #fff;
}

button.btn.btn-sm.success {
  background-color: green;
  color: #fff;
}

.lbdvs {
  color: #fff;
  font-weight: 500;
  margin: 0 10px;
}

.insetdxbox {
  padding: 10px 5px;
  background-color: #00073d;
  color: #fff;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.mxdvdborder {
  border: 1px solid #ccc;
  max-width: 890px;
  margin: 0 auto !important;
}

.trianglecolor {
  background-color: #e0f761b8;
  padding: 5px 15px;
}

.eddv {
  margin-bottom: 15px;
}

.drvdv.dropdown,
label.lbv.drvdv {
  display: inline-block;
  width: auto;
  vertical-align: middle;
  line-height: 35px;
}

.dropdown-toggle.btn {
  box-shadow: 0 0 2px #000;
}

.clrbg {
  color: #fff;
  padding: 3px 5px;
  border-radius: 3px;
}





body {
  background-color: white;
  margin: auto;
  padding: 0px;
  overflow-x: hidden;
  background-repeat: repeat;
  font-family: 'Inter', sans-serif;
  display: grid;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto
}

img {
  max-width: 100%;
  height: auto;
}

.sec__one {
  position: relative;
  width: 100%;
  display: flex;
  padding: 60px 0;
}

.sec__one h1 {
  text-align: center;
  display: block;
  margin: 0 auto;
  color: #07a8ff;
  font-family: 'Inter', sans-serif;
}

.navBar .logo img {
  float: left;
  max-height: 120px;
}

.is-active {
  color: #ff5800 !important;
}

.navBar .col-md-3 {
  z-index: 7;
}

ul.main-menu {
  display: inline-flex;
  list-style: none;
  margin: 0px auto 0;
  padding: 0;
  z-index: 7;
}

ul.main-menu>li.menu-item:first-child {
  padding-left: 0;
  margin-left: 0;
}

.menu-item {
  line-height: 23px;
}

li {
  list-style-type: none;
}

.menu-item a {
  font-size: 16px;
  font-weight: 400;
  position: relative;
  display: block;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.logomargin {
  margin: 0;
  color: #fff;
  text-decoration: none;
}

.row.mb-3.tbussz {
  margin: 20px 0;
}

.menumargin {
  padding: 16px;
}

.header__middle__logo {
  display: inline-block;
}

/* .header__middle__menus {
  display: inline-block;
} */
.menu-item.menu__qqqitem a {
  margin: 27px 10px;
}

.header__middle__search {
  width: 7%;
  display: inline-block;
  padding: 36px 3px 27px 3px;
  border-left: 1px solid lavender;
}

.header__middle {
  /* display: flex; */
  width: 100%;
  background: rgb(0, 7, 61);
  position: relative;
}

.header__middle__logo>a>h1 {
  color: #07a8ff;
  text-transform: uppercase;
  text-decoration: none;
  margin-top: 33px;
}

/* .active{
  color: #ff1800!important;
} */
.header__middle__menus span.text {
  position: absolute;
  right: 177px;
  bottom: 10px;
  font-size: 14px;
  color: #ff1800;
}

.sub__menus__arrows {
  position: relative;
}

.sub__menus__arrows svg,
.sub__menus__arrows__full svg {
  padding-top: 0px;

}

.sub__menus {
  position: absolute;
  display: none;
  background: white;
  border: 1px solid lavender;
  width: 227px;
  left: -17px;
  padding: 2px 0 0 0;
  z-index: 1000;
  box-shadow: 0 10px 12px rgb(22 33 39 / 25%);
}

@media (max-width:767px) {
  .header__middle .active {
    color: #ff3d00 !important;
  }
}

@media (min-width:992px) {
  .sub__menus__arrows:hover .sub__menus {
    display: block;
  }

  .sub__menus__arrows__full:hover .sub__menus {
    display: block;
    z-index: 1000000;
  }
}

.menu-item .sub__menus a {
  color: #000;
  padding: 2px 13px;
}

.menu-item:hover>a {
  color: #07a8ff !important;
}

.menu-item .sub__menus li:hover a {
  color: #07a8ff !important;
}

.header__middle__logo img {
  max-width: 207px;
  margin: 17px 0 0 0;
}

@media(max-width:991px) {
  .header__middle__logo {
    width: 77%;
  }

  /* .header__middle__menus {width: 20%;} */
  .header__middle__logo img {
    max-width: 197px;
    margin: 27px 0 0 0;
  }

  .main-nav .menubar__button:hover {
    background-color: #f44336;
  }

  .main-nav .menu-item:hover>a {
    color: #07a8ff !important
  }

  .main-nav .menubar__button {
    display: block !important;
    float: right;
    background-color: #080808;
    color: #fff;
    padding: 6px 7px;
    border-radius: 5px;
    margin: 9px;
    cursor: pointer;
    position: relative;
    z-index: 10037;
  }

  .main-nav .menubar__button svg {
    font-size: 27px;
  }

  .main-nav ul.menuq2 {
    display: block !important;
    position: absolute;
    left: 0;
    z-index: 10007;
    background-color: #00073d;
    right: 0;
    padding: 5px 0;
  }

  .main-nav .menu-item a {
    margin: 10px 40px;
    text-align: left;
    color: #fff;
  }

  .main-nav ul.main-menu {
    display: none
  }

  .main-nav .sub__menus__arrows:hover .sub__menus {
    margin: 0 auto;
    left: 0;
    right: 0;
  }

  .sub__menus__Active {
    display: block !important;
    margin: 0 auto;
    left: 0;
    right: 0;
  }

  .sub__menus__Active2 {
    display: block !important;
  }

  .menus__categorysss {
    display: none;
  }

  .menus__categorysss2 {
    display: none;
  }

  .menu-item .sub__menus__full a {
    text-align: left;
  }

  nav.main-nav {
    margin-left: inherit;
    margin-top: inherit;
    display: inline;
  }
}

.mdvd {
  margin-bottom: 15px;
}

.mdv {
  display: inline-block;
  vertical-align: middle;
}

.dvdv {
  margin: 0 10px;
}

.mdvd button.btn.btn-sm.mz.bg-primary {
  color: #fff;
}

.modalsz {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: none;
  overflow: auto;
  background-color: #000000;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
}

.modalsz-window {
  position: relative;
  background-color: #FFFFFF;
  width: 50%;
  margin: 10% auto;
  padding: 20px;
}

.modalsz-window.large {
  width: 75%;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  color: rgba(0, 0, 0, 0.3);
  height: 30px;
  width: 30px;
  font-size: 30px;
  line-height: 30px;
  text-align: center;
}

.close:hover,
.close:focus {
  color: #000000;
  cursor: pointer;
}

.opensz {
  display: block;
}