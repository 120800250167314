:root {
    --green-color: rgb(0, 7, 61, 1);
    --blue-color: rgb(57, 35, 252);
    --light-gray: rgb(197, 197, 197);
}

body {
    /* background-color: var(--green-color); */
    font-family: "Source Sans Pro", sans-serif;
}

.main_screen {
    height: 100vh;
    width: 100%;

    ;
}

.main-containerlogin {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: white;
}

.containerlogin {
    width: 400px;
    height: 332px;
    border-radius: 7px;
    box-shadow: 0 6px 12px rgba(179, 179, 179, 0.7);
    z-index: 1;
    overflow: hidden;
}

.main_title {
    margin-top: 10px;
    color: white;
}

.form {
    width: 100%;
    height: 100%;
    display: flex;
    /* padding: 0px !important; */
    padding-bottom: 30px;
    flex-direction: column;
    align-items: center;
    background-color: rgb(0, 7, 61, 0.9);
    justify-content: space-evenly;
}

a {
    text-decoration: none;
    cursor: pointer;
    color: white;
}

.device_link {
    text-decoration: none;
    background-color: rgb(0, 7, 61, 0.9);
    padding: 5px 8px;
    border-radius: 5px;
    color: white;
}

.container_main {
    padding: 10px;
    /* border: 1px solid black; */
    border-radius: 5px;
    background: #ffffff;
    box-shadow: 20px 20px 60px #d9d9d9,
        -20px -20px 60px #ffffff;
}

.cnt_div {
    margin-top: 10px;
}

.device_link :hover {
    color: white;
}

a:focus,
a:hover {
    color: var(--blue-color);
    font-weight: bold;
}

.input {
    position: relative;
}

.input input,
.login-btn {
    width: 260px;
    height: 45px;
    outline: none;
    padding: 0 0.7rem;
    border-radius: 3px;
    transition: 0.2s;
    font-weight: bold;
    z-index: 1;
}

.input input {
    border: none;
    border-bottom: 1px solid #8c8c8c;
}

.login-btn {
    border: 1px solid #8c8c8c;
    background: white;
    cursor: pointer;
    font-size: 20px;
    color: rgb(0, 7, 61);
    text-transform: uppercase;
}

.login-btn:focus,
.login-btn:hover {
    background: #fff;
    cursor: pointer;
    color: var(--green-color);
    text-transform: uppercase;
    border: 1px solid var(--green-color);
    transition: ease-in-out 0.2s;
}

.input label {
    position: absolute;
    top: 28%;
    left: 10px;
    font-size: 0.9rem;
    transition: 0.2s;
    font-weight: bold;
    padding: 0 0.1rem;
}

.input input:focus {
    border-bottom: 1px solid var(--green-color);
}

.input input:focus~label,
.input input:valid~label {
    transform: translateY(-25px);
    font-size: 15px;
    color: var(--green-color);
    background: #fff;
}